import { Grid, IconButton, Modal } from '@mui/material';
import { Box } from '@mui/system';
import SoftTypography from 'components/SoftTypography';
import React from 'react';
import PropTypes from "prop-types";
import style from './style';
import { Close } from '@mui/icons-material';

const MyModal = (props) => {

    return (
        <Modal open={props.open} onClose={(_, reason) => {
            if (props.backdrop === "static" && reason === "backdropClick") return;
            props.onClose()
        }}>
            <Box sx={style(props.size)}>
                <Grid container>
                    <Grid mb={1} item xs={12} sm={12} sx={{ position: "relative" }}>
                        <IconButton onClick={props.onClose} sx={{position:"absolute",right:-10,top:-15}} aria-label="close">
                            <Close/>
                        </IconButton>
                        <SoftTypography>{props.title}</SoftTypography>
                    </Grid>
                </Grid>
                {props.children}

            </Box>
        </Modal>
    );
};


MyModal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    size: PropTypes.oneOf["sm", "lg", "md", "xs"],
    backdrop:PropTypes.oneOf["static"]
}

export default MyModal;
import { Grid,CircularProgress } from '@mui/material';
import React from 'react';

const ScreenLoader = () => {
    return (
     <Grid
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    sx={{ height: "100vh", width: "100wh" }}
  >
    <CircularProgress color="secondary" />
  </Grid>
    );
};

export default ScreenLoader;
import { SignInBasic, SignUpBasic, ForgetBasic, ResetBasic, LockBasic, VerificationBasic,OtpVerify,NewPass } from "layouts";
import LazyLoader from "components/lazyLoader";

const authRoutes =  [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/sign-in",
            component: (
              <LazyLoader>
                <SignInBasic />
              </LazyLoader>
            ),
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/sign-up",
            component: (
              <LazyLoader>
                <SignUpBasic />
              </LazyLoader>
            ),
          },
        ],
      },
      {
        name: "Forget Password",
        key: "forget-password",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/forget-password",
            component: (
              <LazyLoader>
                <ForgetBasic />
              </LazyLoader>
            ),
          },
          {
            name: "Verify Otp",
            key: "verifyOtp",
            route: "/verify-otp",
            component: (
              <LazyLoader>
                <OtpVerify />
              </LazyLoader>
            ),
          },
          {
            name: "New Pass",
            key: "newPass",
            route: "/new-pass",
            component: (
              <LazyLoader>
                <NewPass />
              </LazyLoader>
            ),
          },

        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/reset-password/:token",
            component: (
              <LazyLoader>
                <ResetBasic />
              </LazyLoader>
            ),
          },
        ],
      },
      {
        name: "Lock",
        key: "lock",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/lock",
            component: <LockBasic />,
          },
        ],
      },
      {
        name: "2-Step Verification",
        key: "2-step-verification",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/verification",
            component: <VerificationBasic />,
          },
        ],
      },
     
]
    
export default authRoutes

import { lazy } from "react";

export { default as Mainlayout } from "./mainlayout";

export const SignInBasic = lazy(() => import("layouts/authentication/sign-in/basic"));
export const SignUpBasic = lazy(() => import("layouts/authentication/sign-up/basic"));
export const ForgetBasic = lazy(() => import("layouts/authentication/forget-password/basic"));
export const OtpVerify = lazy(() => import("layouts/authentication/forget-password/verifyOtp"));
export const NewPass = lazy(() => import("layouts/authentication/forget-password/newPass"));
export const ResetBasic = lazy(() => import("layouts/authentication/reset-password/basic"));
export const LockBasic = lazy(() => import("layouts/authentication/lock/basic"));
export const VerificationBasic = lazy(
  () => import("layouts/authentication/2-step-verification/basic")
);
export const Default = lazy(() => import("./dashboards"));
export const Charts = lazy(() => import("./pages/charts"));

//main app pages

// export const List = lazy(() => import("./pages/exampleManagement/Profile"));
// export const Form = lazy(() => import("./pages/exampleManagement/Profile/addForm"));
// export const Details = lazy(
//   () => import("./pages/exampleManagement/details")
// );
export const UserManagementList = lazy(() => import("./pages/userManagement"));
export const AdminManagementList = lazy(() => import("./pages/adminManagement"));
export const AdminForm = lazy(() => import("./pages/adminManagement/addForm"));
export const AdminDetailsForm = lazy(() => import("./pages/adminManagement/viewForm"));
export const VehicleManagementList = lazy(() => import("./pages/vehicleManagement"));
export const EquipmentManagementList = lazy(() => import("./pages/equipmentManagement"));
export const UserForm = lazy(() => import("./pages/userManagement/addForm"));
export const UserDetailsForm = lazy(() => import("./pages/userManagement/viewForm"));
export const EquipmentForm = lazy(() => import("./pages/equipmentManagement/addForm"));
export const EquipmentDetailsForm = lazy(() => import("./pages/equipmentManagement/viewForm"));
export const VehicleForm = lazy(() => import("./pages/vehicleManagement/addForm"));
export const VehicleDetailsForm = lazy(() => import("./pages/vehicleManagement/viewForm"));
export const ModuleManagementList = lazy(() => import("./pages/moduleManagement"));
export const ModuleManagementForm = lazy(() => import("./pages/moduleManagement/addForm"));

export const RoleManagementList = lazy(() => import("./pages/rolePermissionManagement"));
export const RoleManagementForm = lazy(() => import("./pages/rolePermissionManagement/addForm"));
export const RoleManagementPosition = lazy(() => import("./pages/rolePermissionManagement/positionDetail"));

//export const MaintenanceRequest = lazy(() => import("./pages/maintenanceRequest"));
export const MaintenanceView = lazy(() => import("./pages/maintenanceRecord"));
export const TimesheetManagementList = lazy(() => import("./pages/timesheetManagement"));
export const TimesheetDetailsForm = lazy(() => import("./pages/timesheetManagement/viewForm"));
export const JSEAManagementList = lazy(() => import("./pages/JSEAManagement"));
export const JSEADetailsForm = lazy(() => import("./pages/JSEAManagement/viewForm"));
export const NearMissReport = lazy(() => import("./pages/IncidentManagement/NearMissReport"));
export const UndergroundserviceReport = lazy(() => import("./pages/IncidentManagement/UndergroundServiceReport"));
export const QualityserviceReport = lazy(() => import("./pages/IncidentManagement/QualityServiceReport"));
export const Environmentreport = lazy(() => import("./pages/IncidentManagement/EnvironmentReport"));
export const DamagedEquipmentreport = lazy(() => import("./pages/IncidentManagement/DamageEquipmentReport"));
export const SmallPlantreport = lazy(() => import("./pages/smallPlantManagement "));
export const RoleAssign = lazy(() => import("./pages/RoleAssignManagement"));
export const RoleAssignEdit = lazy(() => import("./pages/RoleAssignManagement/addForm"));
export const CMSlist = lazy(() => import("./pages/Content-management/CMSManagement"));
export const CMSForm = lazy(() => import("./pages/Content-management/CMSManagement/addForm"));
export const ContentManagement = lazy(() => import("./pages/Content-management/ContentManagement"));
//export const ContentForm = lazy(() => import("./pages/Content-management/ContentManagement/addForm"));
export const DocumentManagement = lazy(() => import("./pages/document-management/UploaderManagement"));
export const ViewDocument = lazy(() => import("./pages/document-management/ApprovalManagement"));
export const SwmsDocument = lazy(() => import("./pages/SWMSManagement"));
export const AddSwmsDocument = lazy(() => import("./pages/SWMSManagement/addForm"));
export const DynamicForm = lazy(() => import("./pages/dynamic-form-management"));
export const DynamicFormList = lazy(() => import("./pages/dynamic-form-management/viewForm"));

export const ViewDynamicFormList = lazy(() => import("./pages/dynamic-form-management/addForm"));
export const ViewSubmittedFormList = lazy(() => import("./pages/dynamic-form-management/submittedForm"));
export const EditDynamicFormList = lazy(() => import("./pages/dynamic-form-management/editForm"));
export const PlantAncilliaryEquipment = lazy(() => import("./pages/plantAncilliaryEquipment"));
export const VehiclePrestart = lazy(() => import("./pages/vehiclePrestart"));
export const VaccumExcavator = lazy(() => import("./pages/vaccumExcavator"));
export const AirCompressor = lazy(() => import("./pages/airCompressorPrestart"));
export const DrillingRig = lazy(() => import("./pages/DrillingRig"));
export const EarthMovingPlant = lazy(() => import("./pages/earthMovingPlant"));
export const TaxInvoice = lazy(() => import("./pages/IncidentManagement/TaxInvoice"));
export const EmployeeReimbursement = lazy(() => import("./pages/IncidentManagement/EmployeeReimbursement"));
export const PlantAncilliaryform = lazy(() => import("./pages/plantAncilliaryEquipment/addForm"));
export const SmallPlantform = lazy(() => import("./pages/smallPlantManagement /addForm"));
export const TrailerPrestart = lazy(() => import("./pages/trailerPrestart"));
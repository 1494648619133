import axios from "axios";

export default axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 30000,
	withCredentials: true,
	headers: {
		user: "admin",
		"Content-Type": "application/json",
	},
});

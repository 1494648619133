

export const ROUTEURLS = {
    UserList: '/user',
    AdminList:"/user/admin",
    AdminAddForm: '/user/admin/form',
    AdminEditForm: '/user/admin/form/:id',
    AdminView: '/user/admin/view/:id',
    AdminViews: '/user/admin/view',
    UserAddForm: '/user/form',
    UserEditForm: '/user/form/:id',
    UserView: '/user/view/:id',
    UserViews: '/user/view',
    VehicleList: '/vehicle',
    VehicleAddForm: '/vehicle/form',
    VehicleEditForm: '/vehicle/form/:id',
    VehicleView: '/vehicle/view/:id',
    VehicleViews: '/vehicle/view',
    EquipmentList: '/equipment',
    EquipmentAddForm: '/equipment/form',
    EquipmentEditForm: '/equipment/form/:id',
    EquipmentView: '/equipment/view/:id',
    EquipmentViews: '/equipment/view',
    ModuleList: "/module/rolemanagement",
    ModuleForm: '/module/role-management/form',
    ModuleEditForm: '/module/role-management/form/:id',
    RoleAssign: "/role-management/role-assign",
    RoleAddAssign: "/role-management/role-assign",
    RoleAssignEdit: "/role-management/role-assign/:id",
    RoleList: '/role-management/role',
    RoleAddForm: '/role-management/role/form',
    RoleEditForm: '/role-management/role/form/:id',
    RolePosition: '/role-management/role/position',
    MaintenanceRecord: '/maintenance/Record',
    MaintenanceRequest: '/maintenance/Request',
    maintenanceView:"/maintenance/view",
   
    TimesheetList: '/weekly-timesheet',
    TimesheetView: '/timesheet/view/:id',
    TimesheetViews: '/timesheet/view',
    JSEAList: '/job-safety-environment',
    JSEAViewLists: "/job-safety-environment/view/:id",
    JSEAViewList: '/job-safety-environment/view',
    NearMissReport: '/incident/near-miss-report',
    Undergroundreport: '/incident/underground-report',
    QualityserviceReport: '/incident/quality-report',
    EnvironmentReport: '/incident/environment-report',
    DamageEquipmentReport: '/incident/damage-report',
    TaxInvoice: '/incident/tax-invoice',
    EmployeeReimbursement: '/incident/employee-reimbursement',
    SmallPlantAncillary: "/plant-ancillary-equipment",
    PlantAncillaryEquipment: "/ancillary-equipment",
    CmsManagement: "/cms-management",
    ContentManagement: "/content-management",
    Content_Page: "/content-management/:id",
    CreateContent: "/content-management",
    EditContent: "/content-management/edit/:id",
    DeleteContent: "/content_management/id",
    Document_uploader: "/document-approval",
    ViewDocument: "/document-approval/:id",
    SwmsDocument: "/swms_management",
    CreatSwms: "/swms_management/create",
    DynamicForm:"/dynamic-form-management/create",
    DynamicFormList:"/dynamic-form-management",
    ViewDynamicFormList:"/dynamic-form-management/:id",
    EditDynamicFormList:"/dynamic-form-management/create/:id",
    SubmittedFormList:"/dynamic-form-management/submittedForm",
 SubmittedFormListId:"/dynamic-form-management/submittedForm/:id",
 vehiclePrestart: "/prestart/vehicle",
 VaccumExcavator:"/prestart/vaccum-excavator",
 AirCompressor:"/prestart/air-compressor",
 DrillingRig:"/prestart/drilling-rig",
 EarthMovingPlant:"/prestart/earth-moving",
 PlantancilliaryAddForm:"/ancillary-equipment/form",
 PlantancilliaryEditForm:"/ancillary-equipment/form/:id",
  SmallPlantAddForm:"/Small-plant-equipment/form",
 SmallPlantEditForm:"/Small-plant-equipment/form/:id",
 TrailerPrestart:"/prestart/trailer",
}
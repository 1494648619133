import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { SoftBox, SoftTypography } from "components";
import { Grid } from "@mui/material";
import { Base64 } from "_helper/_base64";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

const FileUpload = ({ name, muiltple, setFieldValue }) => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState("");
  const [error, setError] = useState({ error: false, fileId: "" });

  const changeHandler = (e) => {
    const uploadedFiles = e.target.files;
    Object.keys(uploadedFiles).forEach((element) => {
      Base64(uploadedFiles[element]).then((file) => {
        setFiles((prev) => [...prev, file]);
      });
    });
  };

  const removeFile = (id) => {
    const newFile = files.filter((file) => file.lastModified !== id);
    setFile("");
    setFiles(newFile);
    if (error.error) {
      if (error.fileId === id) {
        setError({ error: false, fileId: "" });
      }
    }
  };

  const fileRendrer = (files) => {
    return files.map((file) => {
      const name = file.name;
      // const fileType = file.type;

      return (
        <>
          <Grid
            key={file.lastModified}
            item
            sx={{ marginTop: 1 }}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <span>
              <SoftTypography variant="caption">{name}</SoftTypography>
              <DeleteIcon
                role="button"
                onClick={() => removeFile(file.lastModified)}
                className="cursor-pointer"
              />
            </span>
          </Grid>
        </>
      );
    });
  };

  useEffect(() => {
    setFieldValue(name, files);
  }, [files]);

  return (
    <div>
      <input
        type="file"
        muiltple={muiltple}
        style={{
          width: "100%",
          border: "1px solid #495057",
          borderRadius: "5px",
          padding: "5px 10px",
        }}
        value={file}
        onChange={changeHandler}
      />
      <Grid>{fileRendrer(files)}</Grid>
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </div>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  muiltple: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default FileUpload;
0
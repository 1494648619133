/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components

//import { IconButton } from "@mui/material";
//import { Delete } from "@mui/icons-material";
import PropTypes from "prop-types";
//import {  useNavigate } from "react-router-dom";
//import { useQueryClient } from "@tanstack/react-query";

function unauthorisedAlert(props) {
  
//eslint-disable-next-line
console.log(props,"props")
  Swal.fire({
    title: 'Unauthorized Access',
    text: props.message,
    icon: 'error',
    confirmButtonText: 'OK',
  }).then(() => {
    // Navigate to module list or perform another action after alert is closed
    props.setUnauthorizedSlug(null)
  });
};




unauthorisedAlert.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "action",
    "disabled",
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
};

unauthorisedAlert.defaultProps = {
  color: "secondary",
};

export default unauthorisedAlert;

import { SET_ACCESS_PATH, SET_AUTH, SET_AUTH_USER, SET_LOGOUT, SET_PROFILE_PIC } from "./action";
import config from "appConfig/config";

const authReducer = (state, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_AUTH_USER:
      return {
        ...state,
        user: action.payload.user,
        rolePermission: action.payload.rolePermission,
      };
    case SET_PROFILE_PIC:
      return {
        ...state,
        profilePic: action.payload,
      };
    case SET_LOGOUT: {
      return {
        ...state,
        accessToken: "",
        user: {},
        profilePic: "",
        accessRoutes: config.defaultRouteAccess
      };
    }
    case SET_ACCESS_PATH:
      return {
        ...state,
        accessRoutes: action.payload,
      };
  }
};

export default authReducer;

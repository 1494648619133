


export const APIURLS = {
	SignIn: "/auth/admin",
	ForgetPassword: "/auth/forget-password",
	ResetPassword: "/auth/reset-password",
	OtpVerify: "/auth/verify-otp",
	NewPass:"/auth/reset-password",
	UserList: "/admin/user",
	UserEdit: "/admin/user",
	UserCreate: "/admin/user",
	//UserAllList:"/admin/user/list-all-users",
	UserDelete: "/admin/user",
	UserStatusChange: "/admin/user",
	countryList: "user/get-all-countries",
	StateList: "user/get-state-countries",
	vehicleList: "/admin/vehicle",
	vehicleDelete: "/admin/vehicle",
	equipmentList: "/admin/equipment",
	equipmentDelete: "/admin/equipment",
	prestartCategory: "/admin/vehicle/prestart",
	ModuleList: "/admin/role-management/module",
	GetModule: "/admin/role-management/module",
	UpdateModule: "/admin/role-management/module",
	UpdateStatusModule: "/admin/role-management/module/status",

	RoleAssign: "/admin/role-management/role-assign",
	RoleList: "/admin/role-management/role",
	CreateRole: "/admin/role-management/role/create",
	EditRole: "/admin/role-management/role",
	
	GetSingleRole: "/admin/role-management/role/",
	RolePositionChange: "/admin/role-management/role/change-position",
	maintenance_list: "/admin/maintenance",
	maintenance_pds__list: "/admin/get-maintenance-pdsid",
	
	deleteMaintenance:"/admin/maintenance",
	maintenanceStatus:"/admin/maintenance",
	
	timesheet_list: "/admin/weekly-timesheet",
	jsea_list: "/admin/job-safety-environment",

	nearMissReport: "/admin/incident/near-miss-report",
	undergroundServiceReport: "/admin/incident/underground-report",
	qualityServiceReport: "/admin/incident/quality-report",
	environmentReport: "/admin/incident/environment-report",
	damagedEquipmentReport: "/admin/incident/damage-report",
	
	listPlant: "/admin/plant",
	CreatePage: "/admin/cms-management/page",
	listPage: "/admin/cms-management/page",
	editPage: "/admin/cms-management/page",
	deletePage: "/admin/cms-management/page",
	CreateContent: "/admin/cms-management/content",
	listContent: "/admin/cms-management/content",
	editContent: "/admin/cms-management/content",
	deleteContent: "/admin/cms-management/content",
	listDocument: "/admin/document-approval",
	PageStatusChange: "/admin/cms-management/content",
	SwmsDocuments: "/admin/swms_management",
	SwmsCreate: "/admin/swms_management",
	SwmsDelete:"/admin/swms_management",
	UploadFile:"/user/upload",
	CreateDynamicForm:"/admin/dynamic-form-management",
	listAncilliaryEquipment:"/admin/ancillary-equipment",
    ListUserDynamicForm:"/admin/submit/form",
	VehiclePrestart:"/admin/prestart/vehicle-prestart",
	VaccumExcavator:"/admin/prestart/vaccum-excavator",
	AirCompressor:"/admin/prestart/air-compressor",
	DrillingRig:"/admin/prestart/drilling-rig",
	EarthMovingPlant:"/admin/prestart/earth-moving",
	TrailerPrestart:"/admin/prestart/trailer",
	recieptSubmission:"/admin/receipt-submission",
	ExportDynamic:"/admin/user/export"
};
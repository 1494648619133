import axios from "axios";
import useAuth from "./useAuth";
import useRefreshedToken from "./useRefereshedToken";
import { useEffect } from "react";
import { toast } from "react-toastify";

const useApi = () => {
  const { accessToken, logoutHandler,setAccessToken } = useAuth();
  const refresh = useRefreshedToken();
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 300000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      user: "admin",
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    const requestIntercept = api.interceptors.request.use(
      (config) => {
        if (!config.headers[`Authorization`]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = api.interceptors.response.use(
      (response) => response,
      async (error) => {
      
        const prevRequest = error?.config;
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message.includes("UNAUTHORIZED USER")
        ) {
          toast.error("Session Time-Out");
          logoutHandler();
        }
        if (error?.response?.status === 401 && !prevRequest.sent) {
          prevRequest.sent = true;
          const newAccessToken = (await refresh()).accessToken;
          setAccessToken(newAccessToken);
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return api(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(responseIntercept);
      api.interceptors.request.eject(requestIntercept);
    };
  }, []);

  const GET = async (path, config = {}) => {
    try {
      const response = await api.get(path, config);
      const data = response.data;
      return data;
    } catch (error) {
      const serverError = error.response.data;
      if (serverError.message === "No Session") {
        toast.error("No Session");
        logoutHandler();
      }

      throw new Error(serverError?.message);
    }
  };

  const POST = async (path, body = {}, config = {}) => {
    try {
      const response = await api.post(path, body, config);
      const data = response.data;
      return data;
    } catch (error) {
      const serverError = error.response.data;
      throw new Error(serverError.message);
    }
  };

  const PUT = async (path, body) => {
    try {
      const response = await api.put(path, body);
      const data = response.data;
      return data;
    } catch (error) {
      const serverError = error.response.data;
      throw new Error(serverError.message);
    }
  };
  const PATCH = async (path, body) => {
    try {
      const response = await api.patch(path, body);
      const data = response.data;
      return data;
    } catch (error) {
      const serverError = error.response.data;
      throw new Error(serverError.message);
    }
  };

  const DELETE = async (path) => {
    try {
      const response = await api.delete(path);
      const data = response.data;
      return data;
    } catch (error) {
      const serverError = error.response.data;
      throw new Error(serverError.message);
    }
  };

  const NOTIFICATION_EVENT = () => {
    const customHeaders = {
      authorization: `Bearer ${accessToken}`,
    };
    const queryString = Object.entries(customHeaders, { withCredentials: true })
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");
    const eventSource = new EventSource(
      `${import.meta.env.VITE_API_URL}/notification/socket?${queryString}`
    );

    return eventSource;
  };

  return { POST, PUT,PATCH, GET, DELETE, NOTIFICATION_EVENT };
};

export default useApi;

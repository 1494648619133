import { useState, useEffect } from 'react';
import { useAuth } from "hooks";

function CheckPermissions(module_slug) {
  const { rolePermission } = useAuth();

  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    view: false,
    delete: false
  });

  useEffect(() => {
    // Your custom logic that uses props or performs side effects
    const getModuleDetails = rolePermission.filter((each) => {
        return module_slug.replace('/','') == each.module_slug
    })[0];
   
    if(getModuleDetails) {
        let scopes={};
        getModuleDetails.scopes.map((every) => {
            scopes[every.key]= every.value;
        });
        setPermissions(scopes)
    }
  }, [rolePermission]); // Only re-run the effect if value changes

  // Expose the value and a function to update it
  return [permissions];
}

export default CheckPermissions;
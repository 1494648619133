import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { useAuth, useApi } from "hooks";
import { useMutation } from "@tanstack/react-query";

export default function Logout(props) {
  const { open, dialogHandler } = props;
  const { logoutHandler } = useAuth();
  const { POST } = useApi();
  const clearSession = useMutation({ mutationFn: async () => await POST("/auth/logout") });

  const handleConfirmation = () => {
    clearSession.mutate("", {
      onSuccess: () => {
        logoutHandler();
      
        dialogHandler(false);
      },
      onError: () => {},
    });
  };

  const handleClose = () => {
    dialogHandler(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure to logout?"}</DialogTitle>
        {clearSession.isPending && (
          <DialogContent>
            <DialogContentText>Signing out ...</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleConfirmation} disabled={clearSession.isPending} autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose} disabled={clearSession.isPending}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Logout.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogHandler: PropTypes.func.isRequired,
};

import { useReducer } from "react";
import AuthContext from "./context";
import authReducer from "./reducer";
import { SET_ACCESS_PATH, SET_AUTH, SET_AUTH_USER, SET_LOGOUT, SET_PROFILE_PIC } from "./action";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import config from "appConfig/config";

const initialState = {
  accessToken: "",
  user: {},
  rolePermission:[],
  profilePic: null,
  accessRoutes: config.defaultRouteAccess,
};

const AuthState = (props) => {
  const queryclient = useQueryClient();
  const navigate = useNavigate();
  const [state, setAuthDispatch] = useReducer(authReducer, initialState);

  const setAccessToken = (token) => {
    setAuthDispatch({
      type: SET_AUTH,
      payload: token,
    });
  };

  const setAuthUser = (user) => {
    setAuthDispatch({
      type: SET_AUTH_USER,
      payload: user,
    });
  };

  const setProfilePic = (url) => {
    setAuthDispatch({
      type: SET_PROFILE_PIC,
      payload: url,
    });
  };

  const setAccessRoute = (paths) => {
    const routes = paths && paths.length > 0 ? paths : [];
    setAuthDispatch({
      type: SET_ACCESS_PATH,
      payload: routes,
    });
  };

  const logoutHandler = () => {
    queryclient.clear();
    navigate("/sign-in", { from: "/", replace: true });
    setAuthDispatch({
      type: SET_LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
    
      value={{
        accessToken: state.accessToken,
        user: state.user,
        rolePermission: state.rolePermission,
        profilePic: state.profilePic,
        accessRoutes: state.accessRoutes,
        setProfilePic,
        setAccessToken,
        setAuthUser,
        logoutHandler,
        setAccessRoute,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  children: PropTypes.node,
};

export default AuthState;
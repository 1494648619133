/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// sweetalert2 components
import Swal from "sweetalert2";

// Sweet Alerts page components

import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";

function DeleteAlert(props) {
  const queryClient = useQueryClient();

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          props.deleteHandler.mutate(props.itemId, {
            onSuccess: async () => {
              Swal.fire("Deleted!", `Successfully deleted.`, "success");
              await queryClient.invalidateQueries([props.queryKey]);
            },
            onError: (err) => {
              Swal.fire("Failed!", err.message, "error");
            },
          });
        }
      });
  };

  return (
    <IconButton>
      <Delete
        color={
          [
            "action",
            "disabled",
            "primary",
            "secondary",
            "error",
            "info",
            "success",
            "warning",
          ].includes(props.color)
            ? props.color
            : "secondary"
        }
        onClick={showAlert}
      />
    </IconButton>
  );
}

DeleteAlert.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  queryKey: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "action",
    "disabled",
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
};

DeleteAlert.defaultProps = {
  color: "secondary",
};

export default DeleteAlert;

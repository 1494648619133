import apiClientFactory from "backend/apiClientFactory";
import useAuth from "./useAuth";
//import { toast } from "react-toastify";


const merge = (a, b, predicate = (a, b) => a === b) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}

const useRefreshedToken = () => {

  const { setAccessToken, setAuthUser, setAccessRoute, accessRoutes } = useAuth();
  const refresh = async () => {
    try {
      const response = await apiClientFactory.get("auth/refreshauthtoken");
      const userData = response.data.data;
      setAuthUser({ user: userData.user, rolePermission: userData.role_permissions.permissions });
      setAccessToken(userData.accessToken);
      let finalAccessRoutes = merge(accessRoutes, userData.role_permissions.module_slugs);
      // Remove 'rolemanagement/module' if it exists
      finalAccessRoutes = finalAccessRoutes.filter(route => route !== 'role-management/module');
      // Check if 'rolemanagement/module' exists in userData.role_permissions.module_slugs
      if (userData.role_permissions.module_slugs.includes('role-management/module')) {
        finalAccessRoutes = merge(finalAccessRoutes, ['module/rolemanagement']);
      }
      setAccessRoute(finalAccessRoutes)

      return { accessToken: data.data.accessToken, user: data.data.user };
    } catch (err) {
      toast.error("Your session has been expired");
      logoutHandler();
      throw new Error(err);
    }
  };

  return refresh;
};

export default useRefreshedToken;

// This is a functional component that displays a user pagination
// Props:
// - numberofpages:  containing number of pages
// - currentpage:  which shows the current page
// - onchange:  function to onchange set the pages

import { Pagination, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function CUPagination({ numberOfPages, currentPage, onChange }) {
  return (
    numberOfPages > 0 && (
      <Stack className="stack_pagi" spacing={10} style={{ padding: "20px 0 20px 10px" }}>
        <Pagination
       
          count={numberOfPages}
          color="info"
          size="small"
          page={currentPage}
          onChange={onChange}
        />
      </Stack>
    )
  );
}

CUPagination.propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CUPagination;

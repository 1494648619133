/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts

import LazyLoader from "components/lazyLoader";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
// import Office from "examples/Icons/Office";
// import SchoolIcon from "@mui/icons-material/School";
// import NewspaperIcon from "@mui/icons-material/Newspaper";

//material icon

// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
// import PunchClockIcon from "@mui/icons-material/PunchClock";
// import SettingsIcon from "examples/Icons/Settings";
// import Basket from "examples/Icons/Basket";
// import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";
// import RedeemIcon from "@mui/icons-material/Redeem";

//import { Default } from "layouts";
//import { CoMarketingForm, PatientList, PatientForm  } from "layouts";
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DescriptionIcon from '@mui/icons-material/Description';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import FactoryIcon from '@mui/icons-material/Factory';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Default,
  // Charts,
  UserManagementList,
  VehicleManagementList,
  EquipmentManagementList,

  UserForm,
  EquipmentForm,
  VehicleForm,
  EquipmentDetailsForm,
  VehicleDetailsForm,
  UserDetailsForm,
  TimesheetManagementList,
  JSEAManagementList,
  TimesheetDetailsForm,
  JSEADetailsForm,
  NearMissReport,
  UndergroundserviceReport,
  QualityserviceReport,
  Environmentreport,
  DamagedEquipmentreport,
  SmallPlantreport,
  RoleAssign,
  RoleAssignEdit,
  CMSlist,
  CMSForm,
  DocumentManagement,
  ViewDocument,
  ContentManagement,
  SwmsDocument,
  AddSwmsDocument,
  DynamicForm,
  DynamicFormList,
  ViewDynamicFormList,
  ViewSubmittedFormList,
  EditDynamicFormList,
  PlantAncilliaryEquipment,
  AdminManagementList,
  AdminForm,
  AdminDetailsForm,
  VehiclePrestart,
  VaccumExcavator,
  AirCompressor,
  DrillingRig,
  EarthMovingPlant,
  TaxInvoice,
  EmployeeReimbursement,
  
} from "layouts";

import { ModuleManagementList, ModuleManagementForm } from "layouts";
import { RoleManagementList, RoleManagementForm, RoleManagementPosition } from "layouts";
import { ROUTEURLS } from 'consts/route-url';
import { PlantAncilliaryform } from "layouts";
import { SmallPlantform } from "layouts";
import { MaintenanceView } from "layouts";

import { TrailerPrestart } from "layouts";



const routes = [
  {
    name: "Dashboard",
    key: "dashboard",
    type: "collapse",
    route: "/",
    icon: <Shop size="12px" />,
    component: (
      <LazyLoader>
        <Default />
      </LazyLoader>
    ),
    noCollapse: true,
  },
  // {
  //   name: "Charts",
  //   key: "charts",
  //   type: "collapse",
  //   route: "/charts",
  //   icon: <Shop size="12px" />,
  //   component: (
  //     <LazyLoader>
  //       <Charts />
  //     </LazyLoader>
  //   ),
  //   noCollapse: true,
  // },
  {
    name: "User Management",
    key: "user",
    icon: <CustomerSupport size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "User List",
        key: "user",
        route: ROUTEURLS.UserList,
        component: (
          <LazyLoader>
            <UserManagementList />
          </LazyLoader>
        ),
      },
      {
        name: "Admin List",
        key: "admin",
        route: ROUTEURLS.AdminList,
        component: (
          <LazyLoader>
            <AdminManagementList />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "Add User",
    key: "user",
    route: ROUTEURLS.UserAddForm,
    component: (
      <LazyLoader>
        <UserForm />
      </LazyLoader>
    ),
  },
  {
    name: "Edit User",
    key: "user",
    route: ROUTEURLS.UserEditForm,
    component: (
      <LazyLoader>
        <UserForm />
      </LazyLoader>
    ),
  },
  {
    name: "View User",
    key: "user",
    route: ROUTEURLS.UserView,
    component: (
      <LazyLoader>
        <UserDetailsForm />
      </LazyLoader>
    ),
  },
  {
    name: "Add Admin",
    key: "admin",
    route: ROUTEURLS.AdminAddForm,
    component: (
      <LazyLoader>
        <AdminForm />
      </LazyLoader>
    ),
  },
  {
    name: "Edit Admin",
    key: "admin",
    route: ROUTEURLS.AdminEditForm,
    component: (
      <LazyLoader>
        <AdminForm />
      </LazyLoader>
    ),
  },
  {
    name: "View Admin",
    key: "admin",
    route: ROUTEURLS.AdminView,
    component: (
      <LazyLoader>
        <AdminDetailsForm />
      </LazyLoader>
    ),
  },
  {
    name: "Vehicle Management",
    key: "vehicle",
    icon: <DirectionsCarFilledIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Vehicle List",
        key: "vehicle",
        route: ROUTEURLS.VehicleList,
        component: (
          <LazyLoader>
            <VehicleManagementList />
          </LazyLoader>
        ),
      },
      // {
      //   name: "Prestart Categories",
      //   key: "prestart-categories",
      //   route: "/users",
      //   component: (
      //     <LazyLoader>          
      //     </LazyLoader>
      //   ),
      // },
    ],
  },
  {
    name: "Add Vehicle",
    key: "vehicle",
    route: ROUTEURLS.VehicleAddForm,
    component: (
      <LazyLoader>
        <VehicleForm />
      </LazyLoader>
    ),
  },
  {
    name: "Edit Vehicle",
    key: "vehicle",
    route: ROUTEURLS.VehicleEditForm,
    component: (
      <LazyLoader>
        <VehicleForm />
      </LazyLoader>
    ),
  },
  {
    name: "View Vehicle",
    key: "vehicle",
    route: ROUTEURLS.VehicleView,
    component: (
      <LazyLoader>
        <VehicleDetailsForm />
      </LazyLoader>
    ),
  },
  {
    name: "Equipment Management",
    key: "equipment",
    icon: <HomeRepairServiceIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Equipment List",
        key: "equipment",
        route: ROUTEURLS.EquipmentList,
        component: (
          <LazyLoader>
            <EquipmentManagementList />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "Add Equipment",
    key: "equipment",
    route: ROUTEURLS.EquipmentAddForm,
    component: (
      <LazyLoader>
        <EquipmentForm />
      </LazyLoader>
    ),
  },
  {
    name: "Edit Equipment",
    key: "equipment",
    route: ROUTEURLS.EquipmentEditForm,
    component: (
      <LazyLoader>
        <EquipmentForm />
      </LazyLoader>
    ),
  },
  {
    name: "View Equipment",
    key: "equipment",
    route: ROUTEURLS.EquipmentView,
    component: (
      <LazyLoader>
        <EquipmentDetailsForm />
      </LazyLoader>
    ),
  },
  {
    name: "Module Management",
    key: "module",
    icon: <DescriptionIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Module List",
        key: "module",
        route: ROUTEURLS.ModuleList,
        component: (
          <LazyLoader>
            <ModuleManagementList />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "Edit Module",
    key: "module",
    route: ROUTEURLS.ModuleEditForm,
    component: (
      <LazyLoader>
        <ModuleManagementForm />
      </LazyLoader>
    ),
  },
  {
    name: "Role Management",
    key: "role-management",
    icon: <SupervisedUserCircleIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Role List",
        key: "role-management",
        route: ROUTEURLS.RoleList,
        component: (
          <LazyLoader>
            <RoleManagementList />
          </LazyLoader>
        ),
      },
      {
        name: "Role Assign",
        key: "role-management",
        route: ROUTEURLS.RoleAssign,
        component: (
          <LazyLoader>
            <RoleAssign />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "Edit Role Assign",
    key: "role-management",
    route: ROUTEURLS.RoleAssignEdit,
    component: (
      <LazyLoader>
        <RoleAssignEdit />
      </LazyLoader>
    ),
  },
  {
    name: "Add Role",
    key: "role-management",
    route: ROUTEURLS.RoleAddForm,
    component: (
      <LazyLoader>
        <RoleManagementForm />
      </LazyLoader>
    ),
  },
  {
    name: "Edit Role",
    key: "role-management",
    route: ROUTEURLS.RoleEditForm,
    component: (
      <LazyLoader>
        <RoleManagementForm />
      </LazyLoader>
    ),
  },
  {
    name: "Role Position",
    key: "role-management",
    route: ROUTEURLS.RolePosition,
    component: (
      <LazyLoader>
        <RoleManagementPosition />
      </LazyLoader>
    ),
  },
  {
    name: "Maintenance Management",
    key: "maintenance",
    icon: <DomainVerificationIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Maintenance List",
        key: "maintenance",
        route: ROUTEURLS.maintenanceView,
        component: (
          <LazyLoader>
            <MaintenanceView />
          </LazyLoader>
        ),
      },
    ],
  },
  
  {
    name: "Timesheet Management",
    key: "timesheet",
    icon: <AccessTimeFilledIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Timesheet List",
        key: "timesheet",
        route: ROUTEURLS.TimesheetList,
        component: (
          <LazyLoader>
            <TimesheetManagementList />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "View timesheet",
    key: "timesheet",
    route: ROUTEURLS.TimesheetView,
    component: (
      <LazyLoader>
        <TimesheetDetailsForm />
      </LazyLoader>
    ),
  },
  {
    name: "JSEA Management",
    key: "job-safety-environment",
    icon: <WorkIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "JSEA List",
        key: "job-safety-environment",
        route: ROUTEURLS.JSEAList,
        component: (
          <LazyLoader>
            <JSEAManagementList />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "View JSEA",
    key: "job-safety-environment",
    route: ROUTEURLS.JSEAViewLists,
    component: (
      <LazyLoader>
        <JSEADetailsForm />
      </LazyLoader>
    ),
  },
  {
    name: "Small Plant Management",
    key: "ancillary-equipment",
    icon: <FactoryIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Small Plant List",
        key: "ancillary-equipment",
        route: ROUTEURLS.SmallPlantAncillary,
        component: (
          <LazyLoader>
            <SmallPlantreport />
          </LazyLoader>
        ),
      },
      {
        name: "Small Plant Ancilliary Equipment",
        key: "ancillary-equipment",
        route: ROUTEURLS.PlantAncillaryEquipment,
        component: (
          <LazyLoader>
            <PlantAncilliaryEquipment />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "add small plant list ",
    key: "ancillary-equipment",
    route: ROUTEURLS.SmallPlantAddForm,
    component: (
      <LazyLoader>
        <SmallPlantform />
      </LazyLoader>
    ),
  },
  
  {
    name: "edit small plant list",
    key: "ancilliary-equipment",
    route: ROUTEURLS.SmallPlantEditForm,
    component: (
      <LazyLoader>
        <SmallPlantform />
      </LazyLoader>
    ),
  },
  {
    name: "add small plant anciliary ",
    key: "ancillary-equipment",
    route: ROUTEURLS.PlantancilliaryAddForm,
    component: (
      <LazyLoader>
        <PlantAncilliaryform/>
      </LazyLoader>
    ),
  },
  {
    name: "edit small plant ancilliary",
    key: "ancilliary-equipment",
    route: ROUTEURLS.PlantancilliaryEditForm,
    component: (
      <LazyLoader>
        <PlantAncilliaryform/>
      </LazyLoader>
    ),
  },
  {
    name: "Reporting",
    key: "incident",
    icon: <EqualizerOutlinedIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Near Miss Report",
        key: "incident",
        route: ROUTEURLS.NearMissReport,
        component: (
          <LazyLoader>
            <NearMissReport />
          </LazyLoader>
        ),
      },
      {
        name: "Underground Service Report",
        key: "incident",
        route: ROUTEURLS.Undergroundreport,
        component: (
          <LazyLoader>
            <UndergroundserviceReport />
          </LazyLoader>
        ),
      },
      {
        name: "Quality Service Report",
        key: "incident",
        route: ROUTEURLS.QualityserviceReport,
        component: (
          <LazyLoader>
            <QualityserviceReport />
          </LazyLoader>
        ),
      },
      {
        name: "Environment Report ",
        key: "incident",
        route: ROUTEURLS.EnvironmentReport,
        component: (
          <LazyLoader>
            <Environmentreport />
          </LazyLoader>
        ),
      },
      {
        name: "Damaged Equipment Report ",
        key: "incident",
        route: ROUTEURLS.DamageEquipmentReport,
        component: (
          <LazyLoader>
            <DamagedEquipmentreport />
          </LazyLoader>
        ),
      },
      {
        name: "TaxInvoice/ReceiptSubmission Report ",
        key: "incident",
        route: ROUTEURLS.TaxInvoice,
        component: (
          <LazyLoader>
            <TaxInvoice />
          </LazyLoader>
        ),
      },
      {
        name: "Employee/Reimbursement  Report ",
        key: "incident",
        route: ROUTEURLS.EmployeeReimbursement,
        component: (
          <LazyLoader>
            <EmployeeReimbursement />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "Content Management System",
    key: "cms-management",
    icon: <ContentCopyOutlinedIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "pages",
        key: "cms-management",
        route: ROUTEURLS.CmsManagement,
        component: (
          <LazyLoader>
            <CMSlist />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "Add Content",
    key: "cms-management",
    route: ROUTEURLS.CreateContent,
    component: (
      <LazyLoader>
        <CMSForm />
      </LazyLoader>
    ),
  },
  {
    name: "Edit Content",
    key: "cms-management",
    route: ROUTEURLS.EditContent,
    component: (
      <LazyLoader>
        <CMSForm />
      </LazyLoader>
    ),
  },
  {
    name: "content-management ",
    key: "content-management",
    route: ROUTEURLS.Content_Page,
    component: (
      <LazyLoader>
        <ContentManagement />
      </LazyLoader>
    ),
  },
  // {
  //   name: "Add Content",
  //   key: "content-management",
  //   route: ROUTEURLS.CreateContent,
  //   component: (
  //     <LazyLoader>
  //       <ContentForm />  
  //     </LazyLoader>
  //   ),
  // },
  // {
  //   name: "Edit Content",
  //   key: "content-management",
  //   route: ROUTEURLS.EditContent,
  //   component: (
  //     <LazyLoader>
  //       <ContentForm />
  //     </LazyLoader>
  //   ),
  // },

  {
    name: "Document Management",
    key: "document-approval",
    icon: <NoteAddIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "Document",
        key: "document-approval",
        route: ROUTEURLS.Document_uploader,
        component: (
          <LazyLoader>
            <DocumentManagement />
          </LazyLoader>
        ),
      },
    ],
  },
  {
    name: "View Document",
    key: "document-approval",
    route: ROUTEURLS.ViewDocument,
    component: (
      <LazyLoader>
        <ViewDocument />
      </LazyLoader>
    ),
  },
  {
    name: "SWMS Management",
    key: "swms_management",
    icon: <PictureAsPdfIcon size="12px" />,
    type: "collapse",
    collapse: [
      {
        name: "SWMS List",
        key: "swms_management",
        route: ROUTEURLS.SwmsDocument,
        component: (
          <LazyLoader>
            <SwmsDocument />
          </LazyLoader>
        ),
      },
    ],
  },
  {
       name: "Upload Documents",
      key: "swms_management",
     route: ROUTEURLS.CreatSwms,
      component: (
        <LazyLoader>
         <AddSwmsDocument />
         </LazyLoader>
      ),
     },
     {
      name: "Dynamic Form Management",
      key: "dynamic-form-management",
      icon: <DynamicFormIcon size="12px" />,
      type: "collapse",
      collapse: [
        {
          name: "Create Form",
          key: "dynamic-form-management",
          route: ROUTEURLS.DynamicForm,
          component: (
            <LazyLoader>
              <DynamicForm />
            </LazyLoader>
          ),
        },
        {
          name: "Form List",
          key: "dynamic-form-management",
          route: ROUTEURLS.DynamicFormList,
          component: (
            <LazyLoader>
              <DynamicFormList />
            </LazyLoader>
          ),
        },
      ],
    },
    {
        name: "View Dynamic",
        key: "dynamic-form-management",
        route: ROUTEURLS.ViewDynamicFormList,
        component: (
          <LazyLoader>
            <ViewDynamicFormList />
          </LazyLoader>
        ),
      },
      {
        name: "Edit Dynamic",
        key: "dynamic-form-management",
        route: ROUTEURLS.EditDynamicFormList,
        component: (
          <LazyLoader>
            <EditDynamicFormList />
          </LazyLoader>
        ),
      },
      {
        name: "Submitted User",
        key: "dynamic-form-management",
        route: ROUTEURLS.SubmittedFormListId,
        component: (
          <LazyLoader>
            <ViewSubmittedFormList />
          </LazyLoader>
        ),
      },
      {
        name: "Prestart List",
        key: "prestart",
        icon: <LanguageIcon size="12px" />,
        type: "collapse",
        collapse: [
          {
            name: "Vehicle Prestart",
            key: "prestart",
            route: ROUTEURLS.vehiclePrestart,
            component: (
              <LazyLoader>
                <VehiclePrestart />
              </LazyLoader>
            ),
          },
          {
            name: "Vaccum Excavator Prestart",
            key: "prestart",
            route: ROUTEURLS.VaccumExcavator,
            component: (
              <LazyLoader>
                <VaccumExcavator />
              </LazyLoader>
            ),
          },
          {
            name: "Air Compressor Prestart",
            key: "prestart",
            route: ROUTEURLS.AirCompressor,
            component: (
              <LazyLoader>
                <AirCompressor />
              </LazyLoader>
            ),
          },
          {
            name: "Drilling Rig Prestart ",
            key:"prestart",
            route: ROUTEURLS.DrillingRig,
            component: (
              <LazyLoader>
                <DrillingRig />
              </LazyLoader>
            ),
          },
          {
            name: "Earth Moving Prestart ",
            key: "prestart",
            route: ROUTEURLS.EarthMovingPlant,
            component: (
              <LazyLoader>
                <EarthMovingPlant />
              </LazyLoader>
            ),
          },
          {
            name: "Trailer Prestart ",
            key: "prestart",
            route: ROUTEURLS.TrailerPrestart,
            component: (
              <LazyLoader>
                <TrailerPrestart/>
              </LazyLoader>
            ),
          },
        ],
      },
  // {
  //   name: "Patient",
  //   key: "patient",
  //   icon: <CustomerSupport size="12px" />,
  //   type: "collapse",
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "patient-profile",
  //       route: "/patient",
  //       component: (
  //         <LazyLoader>
  //           <PatientList />
  //         </LazyLoader>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   name: "Add Patient",
  //   key: "add_patient",
  //   route: "/patient/form",
  //   component: (
  //     <LazyLoader>
  //       <PatientForm />
  //     </LazyLoader>
  //   ),
  // },
  // {
  //   name: "Edit Patient",
  //   key: "edit_patient",
  //   route: "/patient/form/:id",
  //   component: (
  //     <LazyLoader>
  //       <PatientForm />
  //     </LazyLoader>
  //   ),
  // },
];

export default routes;

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDate } from "_helper/common";

const BasicFilter = ({
  fields,
  onFilterChange,
  onHandleClick,
  onResetClick,
  filterFields,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleStartDateChange = (date) => {
    const formattedDate = formatDate(date);
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null);
      onFilterChange("endDate", null);
    }
    onFilterChange("startDate", formattedDate);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = formatDate(date);
    setEndDate(date);
    onFilterChange("endDate", formattedDate);
  };

  
  return (
    <div className="filtermanagement_user">
      <Accordion style={{ width: "50%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Filter
        </AccordionSummary>
        <AccordionDetails>
          <form>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                {field.type === "date" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="date-field">
                      <DatePicker
                        label={field.label}
                        value={field.field_name === "startDate" ? startDate : endDate}
                        onChange={(date) =>
                          field.field_name === "startDate"
                            ? handleStartDateChange(date)
                            : handleEndDateChange(date)
                        }
                        renderInput={(props) => <TextField {...props} />}
                        // format="DD-MM-YYYY"
                        minDate={field.field_name === "endDate" ? startDate : null}
                        maxDate={field.field_name === "startDate" ? endDate : null}
                      />
                    </div>
                  </LocalizationProvider>
                ) : field.type === "dropdown" ? (
                  <TextField
                    id="outlined-select-currency"
                    select
                    label={field.label}
                    onChange={(e) => onFilterChange(field.field_name, e.target.value)}
                    value={filterFields[field.field_name] || ""}
                    variant="outlined"
                  >
                    {field.options.map((option) => (
                      <MenuItem key={option.value} open={open} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label={field.label}
                    variant="outlined"
                    onChange={(e) => onFilterChange(field.field_name, e.target.value)}
                    halfWidth
                    value={filterFields[field.field_name] || ""}
                    style={{ marginBottom: "10px" }}
                  />
                )}
              </React.Fragment>
            ))}
            <div className="filter-management-btn">
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                  onHandleClick();
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                  onResetClick();
                }}
              >
                Reset
              </Button>
            </div>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BasicFilter;

import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from "prop-types"

const TableLoader = ({noOfRows,noOfItems}) => {
    return (
        <TableBody >
            {Array.from({ length: noOfRows }).map((_, index) => <TableRow key={index}>
                {Array.from({ length: noOfItems }).map((_, ind) => <TableCell key={ind} sx={{ padding: '10px' }}><Skeleton variant='rounded' height={30} /> </TableCell>)}
                
            </TableRow>)}
        </TableBody>
    );
};

TableLoader.propTypes = {
    noOfRows: PropTypes.number.isRequired,
    noOfItems:PropTypes.number.isRequired
    
}

export default TableLoader;
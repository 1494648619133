// This is a functional component that displays a dialog box
// Props:
// - open: open the dialog box
// - onclose: close the dialog box
// - title: header of the dialog box
// - content: object contaning info
// - cancellabel: cancel the dialogbox
// - confirmlabel: confirm the ok button
// - onconfirm: confirm click the buttton

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import PropTypes from "prop-types";

const MyDialog = ({ open, onClose, title, content, cancelLabel, confirmLabel, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={onConfirm} color="error">
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MyDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default MyDialog;

import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const TableLoader = ({ noOfItems }) => {
  return (
    <Grid container mt={2} spacing={3}>
      {Array.from({ length: noOfItems }).map((_, index) => (
        <Grid item key={index} mb={1.3} xs={12} sm={6}>
          <Box mb={1}>
            <Skeleton variant="rounded" height={10} width={200} />
          </Box>
          <Box>
            <Skeleton variant="rounded" height={40} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

TableLoader.propTypes = {
  noOfItems: PropTypes.number.isRequired,
};

export default TableLoader;

import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";


const errorRoutes = [
          {
            name: "Error 404",
            key: "error-404",
            route: "/error/404",
            component: <Error404 />,
          },
          {
            name: "Error 500",
            key: "error-500",
            route: "/error/500",
            component: <Error500 />,
          },
        ]
      
export default errorRoutes;
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "hooks";
import { useNavigate } from "react-router-dom";

const useDeleteFile = () => {
  
    const navigate = useNavigate();
    const { PUT } = useApi();
    const deleteFile = useMutation({mutationFn:async(body)=>await PUT("/app/delete-any-file",body)}) ;

    const removeFile = (fileLink, fieldName, collectionName, objectId) =>{
		if (fileLink && fieldName && collectionName && objectId) {

			const body = {
				"collection_name": collectionName,
				"field_name": fieldName,
				"object_id": objectId,
				"file_link": fileLink
			}

			deleteFile.mutate(body, {
				onSuccess: (data) => {
					toast.success(data.message);
					navigate(0)
				},
				onError: (error) => {
					toast.error(error.message);
				},
			});
		}
	}
  

  return { removeFile };
};

export default useDeleteFile;

import React from "react";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// @mui material components //eslint-disable-next-line
import Switch from "@mui/material/Switch";
import "./index.css"

const RoleMultipleSwitch = (props) => {
    const { allValues, onChange } = props;

    return (
        <div className="form_inner_level">
            {
                allValues['permissions']?.map((eachOption, eachKey) => {
                    return (
                        <SoftBox key={eachKey} mb={1.5} className="flex_box_grid">
                            <SoftBox mb={1} lineHeight={0}>
                                <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    {eachOption.module_name}
                                </SoftTypography>

                            </SoftBox>
                            <div className="form_grid_bg">
                            {
                                eachOption?.scopes?.map((eachAction, eachActionKey) => {
                                     return (
                                    <SoftBox 
                                        key={eachActionKey}
                                        p={3}
                                        height="100%"
                                        bgColor={eachAction.value?.toString().toLowerCase?.() === 'true' ? "dark" : "white"}
                                        variant="gradient"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                    >

                                        <SoftBox
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mb={2}
                                            lineHeight={1}
                                        >
                                            <SoftTypography variant="body2" color={eachAction.value?.toString().toLowerCase?.() === 'true' ? "white" : "text"}>
                                              {eachAction.key} permission {eachAction.value?.toString().toLowerCase?.() === 'true' ? "on" : "off"}
                                            </SoftTypography>
                                            <SoftBox mr={1}>
                                                <Switch disabled={eachAction.disable} checked={eachAction.value?.toString().toLowerCase?.() === 'true' ? true : false} onChange={(e) => onChange(e, eachKey, eachActionKey, eachAction.key)}  />
                                            </SoftBox>
                                        </SoftBox>
                                       
                                    </SoftBox>
                                )
                                })
                            }
                            </div>
                        </SoftBox>
                    );
                })
            }
        </div>
    )



};

// RoleMultipleSwitch.defaultProps = {
//     apiFetchEnable: true,
//     multiple: false,
//     disabled: false,
// };

// RoleMultipleSwitch.propTypes = {
//     value: PropTypes.array.isRequired,
//     setValue: PropTypes.fu,
// };

export default RoleMultipleSwitch;

/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function SoftSwitchButton({ color, state, icon, onChange }) {
  return (  
      <SoftBox
        p={3}
        height="100%"
        bgColor={state ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          lineHeight={1}
        >
          <SoftTypography variant="body2" color={state ? "white" : "text"}>
            {state ? "On" : "Off"}
          </SoftTypography>
          <SoftBox mr={1}>
            <Switch checked={state} onChange={onChange} />
          </SoftBox>
        </SoftBox>
        {icon}
      </SoftBox>
  );
}

// Setting default values for the props of SoftSwitchButton
SoftSwitchButton.defaultProps = {
  color: "info",
  state: false,
  description: "",
};

// Typechecking props for the SoftSwitchButton
SoftSwitchButton.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  state: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SoftSwitchButton;

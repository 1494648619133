import React from 'react';
import {
    useAuth,
    useRefreshedToken
} from 'hooks';
import { useQuery } from '@tanstack/react-query';
import { Navigate, Outlet } from "react-router-dom";
import { ScreenLoader } from 'components';

const ProtectedRoutes = () => {
    const { accessToken } = useAuth();
    const refresh = useRefreshedToken();
    //const navigate = useNavigate();
    //const location = useLocation();



    const { isLoading } = useQuery({
        queryKey: [], queryFn: async () => await refresh(), enabled: !accessToken,
        retry: 1,
    })

    // useEffect(() => {
    //     if (isError) {
    //         navigate("/sign-in", { state: { from: location, replace: true } });
    //     }
    // }, [isError]);

    if (isLoading) {
        return <ScreenLoader />
    }

    return (
        <>
            {!accessToken ? (
                <Navigate to='/sign-in' />
            ) : (
                <Outlet />
            )}
        </>
    );
};

export default ProtectedRoutes;
import { useState } from "react";

const usePagination = () => {
  const [page, setPage] = useState({ page: 1, totalPage: 10 });

  const handlePagination = (_, value) => {
    if (isNaN(value)) {
      let currentValue = page.page;
      if (currentValue < 0) {
        currentValue = currentValue - 1;
      }
      setPage((prev) => ({ ...prev, page: currentValue }));
    } else if (value === "[object Object]11") {
      let currentValue = page.page;
      if (currentValue < Math.ceil(page.totalPage / 10)) {
        currentValue = currentValue + 1;
      }
      setPage((prev) => ({ ...prev, page: currentValue }));
    } else {
      setPage((prev) => ({ ...prev, page: value }));
    }
  };

  const setTotalPage = (count) => {
    setPage((prev) => ({ ...prev, totalPage: count }));
  };

  return { handlePagination, page: page.page, totalPage: page.totalPage, setTotalPage };
};

export default usePagination;

import React from "react";
// react-router components
import { Routes, Route, Navigate } from "react-router-dom";

import routes from "./routes";
import authRoutes from "./auth.routes";
import errorRoutes from "./error.routes";

import { Mainlayout } from "layouts";
import ProtectedRoutes from "./protected.routes";
import { useAuth } from "hooks";
import config from "appConfig/config";

const MainRoutes = () => {
  const { accessRoutes, user } = useAuth();
  const getRoutes = (allRoutes, main = false) =>
    allRoutes.map((route) => {
      if (!main || accessRoutes.includes(route.key) || user.user_type === config.superAdmin) {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }

      return null;
    });
  return (
    <>
      <Routes>
        {getRoutes(authRoutes)}
        <Route element={<ProtectedRoutes />}>
          <Route element={<Mainlayout />}>{getRoutes(routes)}</Route>
        </Route>
        {getRoutes(errorRoutes)}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Routes>
    </>
  );
};

export default MainRoutes;

export const Base64 = (file) => {

	
	return new Promise((resolve) => {
		let baseURL = "";
		// Make new FileReader
		let reader = new FileReader();

		// Convert the file to base64 text
		reader.readAsDataURL(file);

		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			baseURL = reader.result;

			resolve({
				storage: "base64",
				name:
					file.name === undefined
						? Math.random().toString(16).slice(2)
						: file.name,
				size: file.size,
				type: file.type,
				url: baseURL,
				lastModified: file.lastModified,
				lastModifiedDate: file.lastModifiedDate,
			});
		};
	});
};

import React, { Suspense } from "react";
import { Grid, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const Loader = () => (
  <Grid
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    sx={{ height: "100vh", width: "100wh" }}
  >
    <CircularProgress color="secondary" />
  </Grid>
);

const LazyLoader = (props) => {
  return <Suspense fallback={<Loader />}>{props.children}</Suspense>;
};

LazyLoader.propTypes = {
  children: PropTypes.node,
};

export default LazyLoader;

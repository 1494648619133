import { Skeleton, TableBody, TableCell } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';




const CircleLoader = ({ noOfItems }) => {




    return (
        <TableBody>
            <div className="circular_round loader_rectmwrp">
                <TableCell >
                    {Array.from({ length: noOfItems }).map((_, cellIndex) => (
                       
                        <div key={cellIndex} className="rectroundbox">  <Skeleton className="rectangle_loader" variant="circular" width={30} height={30} />
                        </div>
                        
                    ))}
                </TableCell>
            </div>

        </TableBody>
    );
};

CircleLoader.propTypes = {
    noOfRows: PropTypes.number.isRequired,
    noOfItems: PropTypes.number.isRequired,
};

export default CircleLoader;

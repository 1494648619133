export default (size) => {
  const modalWidth = () => {
    switch (size) {
      case "xs":
        return "40%";
      case "sm":
        return "60%";
      case "lg":
        return "90%";
      default:
        return "70%";
    }
  };

  return {
    position: "absolute",
    top: "300px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: modalWidth(),
    bgcolor: "background.paper",
    borderRadius: 2.5,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
};

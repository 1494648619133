import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import { ErrorMessage } from "formik";
import { useApi } from "hooks";
import PropTypes from "prop-types";

const LOADING = { label: "loading", value: "" };
const ERROR = { label: "error", value: "" };

function getServerValue(object, keyString) {
  const keys = keyString.split(".");
  return keys.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    object
  );
}
 
const ApiSelect = (props) => {
  const { name, value, url, dataPath, optionValue, apiFetchEnable, multiple, disabled } = props;
  const [options, setOptions] = useState([]);
  const { GET } = useApi();
 
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [name, url],
    queryFn: async (query) => await GET(query.queryKey[1]),
    enabled: apiFetchEnable,
  });
 
  useEffect(() => {
    refetch();
  }, [url]);

  useEffect(() => {
    if (data) {
      const serverData = data;
      const optionData = getServerValue(serverData, dataPath);
    
      if (Array.isArray(optionData)) {
        const parseOptionData =
          optionData &&
          optionData.map((data) => ({
            value: data[optionValue.value],
            label: data[optionValue.label],          
          }));
         
        setOptions(parseOptionData);
      }
    }
  }, [data]);

  const optionsData = () => {
    if (isLoading) return LOADING;
    if (isError) return ERROR;
    return options;
  };

  return (
    <>
      <SoftSelect
        {...props}
        type="select"
        name={name}
        options={optionsData()}
        value={
          !multiple
            ? options.filter((item) => item.value === value)
            : options.filter((option) => value?.includes(option.value))
        }
        isMulti={multiple}
        isDisabled={disabled}
      />

      <SoftBox>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </>
  );
};

ApiSelect.defaultProps = {
  apiFetchEnable: true,
  multiple: false,
  disabled: false,
};

ApiSelect.propTypes = {
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  dataPath: PropTypes.string.isRequired,
  optionValue: PropTypes.object.isRequired,
  apiFetchEnable: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ApiSelect;

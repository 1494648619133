import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,

} from "@mui/material";
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomDropdownIcon from "./customicon";

const DocumentModal = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const fieldNames = [
    { label: "Status", value: "status" },
    { label: "Reason", value: "reason" }
  ];
  const statusOptions = ["approved", "declined"];

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
    setErrors({
      ...errors,
      [fieldName]: "",
    });
  };
  const validateForm = () => {
    let valid = true;
    let newErrors = {};
    fieldNames.forEach((field) => {
      if (!formData[field.value]) {
        valid = false;
        newErrors[field.value] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    return valid;
  };
  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit({ ...formData });
      setFormData({});
      setErrors({});

    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent className="modal_input_adjust status_dialog">
          {fieldNames.map((field) =>
            field.value === "status" ? (
              <FormControl fullWidth key={field.value} margin="normal">
                <InputLabel >{field.label}</InputLabel>
                <Select className="status_drpdwn"
                  value={formData[field.value] || ""}
                  IconComponent={CustomDropdownIcon}
                  onChange={(e) => handleChange(e, field.value)}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {errors[field.value] && <FormHelperText className="error-status">{errors[field.value]}</FormHelperText>}
              </FormControl>
            ) : (
              <TextField
                className="error-reason"
                key={field.value}
                fullWidth
                margin="normal"
                label={field.label}
                value={formData[field.value] || ""}
                onChange={(e) => handleChange(e, field.value)}
                helperText={errors[field.value]}
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleFormSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentModal;
